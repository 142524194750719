export const CountriesEnum = [
	{ locale: 'nl-nl', language: 'nl', value: 'Nederlands (NL)' },
	{ locale: 'nl-be', language: 'nl', value: 'Nederlands (BE)' },
	{ locale: 'en', language: 'en', value: 'English' },
	{ locale: 'es', language: 'es', value: 'Español' },
	{ locale: 'fr', language: 'fr', value: 'Français' },
	{ locale: 'de', language: 'de', value: 'Deutsch' },
	{ locale: 'it', language: 'it', value: 'Italiano' },
	{ locale: 'sv', language: 'sv', value: 'Svenska' },
	{ locale: 'no', language: 'no', value: 'Norsk' },
	{ locale: 'da', language: 'da', value: 'Dansk' },
	{ locale: 'pt', language: 'pt', value: 'Portuguesa' },
	{ locale: 'pl', language: 'pl', value: 'Polski' },
	{ locale: 'ro', language: 'ro', value: 'Română' },
	{ locale: 'el', language: 'el', value: 'Ελληνικά' },
	{ locale: 'uk', language: 'uk', value: 'Українська' },
	{ locale: 'hu', language: 'hu', value: 'Magyar' },
	{ locale: 'fi', language: 'fi', value: 'Suomi' },
	{ locale: 'et', language: 'et', value: 'Eesti' },
	{ locale: 'lv', language: 'lv', value: 'Latviešu' },
	{ locale: 'lt', language: 'lt', value: 'Lietuvių' },
	{ locale: 'cs', language: 'cs', value: 'Čeština' },
	{ locale: 'hr', language: 'hr', value: 'Hrvatski' },
	{ locale: 'sl', language: 'sl', value: 'Slovenščina' },
	{ locale: 'sk', language: 'sk', value: 'Slovenčina' },
	{ locale: 'is', language: 'is', value: 'Íslenska' },
];

export const enUs = { locale: 'en-us', language: 'en', value: 'English (US)' };
